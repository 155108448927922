import React from "react"
import ProgramItem from "./programitem"
import styled from "styled-components"

const Header = styled.div`
  h2 {
    display: block;
  }
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
`

function Program(props) {
  let { events } = props
  const filtered = events.filter(event => {
    return event.event.start_time !== null
  })
  const sorted = filtered.sort((a, b) =>
    a.event.start_time > b.event.start_time ? 1 : -1
  )
  const akw = sorted.filter(event => {
    return event.event.eventlocation == "AKW Zwentendorf"
  })
  const akwDayOne = akw.filter(event => {
    return event.event.start_time.startsWith("2020-10-30")
  })

  const akwDayTwo = akw.filter(event => {
    return event.event.start_time.startsWith("2020-10-31")
  })
  let firstParallel = akwDayTwo.find(event => event.event.parallel === true)
  if (firstParallel !== undefined) {
    akwDayTwo.find(
      event => event.event.parallel === true
    ).event.firstparallel = true
  }
  function move(array, from, to) {
    array.splice(to, 0, array.splice(from, 1)[0])
  }

  const kunsthaus = sorted.filter(event => {
    return event.event.eventlocation == "Kunsthaus"
  })
  const parlament = sorted.filter(event => {
    return event.event.eventlocation == "Parlament"
  })
  const melk = sorted.filter(event => {
    return event.event.eventlocation == "Melk"
  })
  const melkDayOne = melk.filter(event => {
    return event.event.start_time.startsWith("2021-09-02")
  })
  const melkDayTwo = melk.filter(event => {
    return (
      event.event.start_time.startsWith("2021-09-03") 
    )
  })
  const seghal1 = melkDayTwo.filter(event => {
    return event.event.allday
  })
    const melkDayTwoNoAllday = melkDayTwo.filter(event => {
      return !event.event.allday
    })
  console.log(seghal1)
  const melkDayThree = melk.filter(event => {
    return event.event.start_time.startsWith("2021-09-04")
  })
    const seghal2 = melkDayThree.filter(event => {
      return event.event.allday
    })
      const melkDayThreeNoAllday = melkDayThree.filter(event => {
        return !event.event.allday
      })
  return (
    <>
      {melkDayOne[0] && (
        <>
          <Header>
            <h2>Stift Melk</h2>
            <h2>02.09.2021</h2>
          </Header>
          {melkDayOne.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {melkDayTwo[0] && (
        <>
          <Header>
            <h2>Stift Melk</h2>
            <h2>03.09.2021</h2>
          </Header>
          <ProgramItem event={seghal1[0].event} />
          {melkDayTwoNoAllday.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {melkDayThree[0] && (
        <>
          <Header>
            <h2>Stift Melk</h2>
            <h2>04.09.2021</h2>
          </Header>
          <ProgramItem event={seghal1[0].event} />
          {melkDayThreeNoAllday.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {kunsthaus[0] && (
        <>
          <Header>
            <h2>KunstHausWien</h2>
            <h2>30.10.2020</h2>
          </Header>
          {kunsthaus.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {akwDayOne[0] && (
        <>
          <Header>
            <h2>AKW Zwentendorf</h2>
            <h2>30.10.2020</h2>
          </Header>
          {akwDayOne.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {akwDayTwo[0] && (
        <>
          <Header>
            <h2>AKW Zwentendorf</h2>
            <h2>31.10.2020</h2>
          </Header>
          {akwDayTwo.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
      {parlament[0] && (
        <>
          <Header>
            <h2>Parlament</h2>
            <h2>28.10.2021</h2>
          </Header>
          {parlament.map(({ event }) => (
            <ProgramItem event={event} />
          ))}
        </>
      )}
    </>
  )
}

export default Program
