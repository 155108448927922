import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Moment from "react-moment"
import { RichText } from "prismic-reactjs"

const Content = styled.div`
  display: grid;
  grid-template-columns: 60px 1fr 1fr;
  grid-gap: 1rem;
  padding: 20px 0;
  line-height: 26px;
  color: ${props => ((props.parallel|| props.allday) ? "#6594D4" : "black")};

  h3 {
    font-family: "Marr Sans Web";
    font-size: 20px;
    line-height: 26px;
    color: ${props => ((props.parallel|| props.allday) ? "#6594D4" : "black")};
    border-bottom: none;
    margin: 0;
    padding-bottom: 0;
  }
  h4 {
    margin-top: 0;
    font-family: "Marr Sans Web";
    font-size: 20px;
    color: ${props => ((props.parallel|| props.allday) ? "#6594D4" : "black")};
  }
  p {
    margin: 0;
    font-size: 20px;
    line-height: 26px;
    color: #000;
    color: ${props => ((props.parallel|| props.allday) ? "#6594D4" : "black")};
  }

  > div > div {
    margin-bottom: 26px;
    display: block;
    color: ${props => ((props.parallel|| props.allday) ? "#6594D4" : "black")};
  }
  @media only screen and (max-width: 900px) {
    width: calc(100% - 2rem);
    grid-template-columns: 60px 1fr;
    grid-template-rows: auto auto;
    div:last-child {
      grid-column-start: 2;
    }
  }
`
const Border = styled.div`
  border-top: ${props =>
    (props.parallel || props.allday)
      ? props.first
        ? "2px solid #6594D4"
        : "2px solid #6594D4"
      : "2px solid black"};
`
const StyledLink = styled(Link)`
  transition: none;
  color: ${props => ((props.parallel || props.allday) ? "#6594D4 !important" : "black")};
  text-decoration: none;
  h3 {
    color: ${props => ((props.parallel || props.allday) ? "#6594D4" : "black")};
  }
`
function ProgramItem(props) {
  const { event } = props
  return (
    <>
      <Border
        parallel={event.parallel}
        allday={event.allday}
        first={event.firstparallel}
      >
        <Content allday={event.allday} parallel={event.parallel}>
          <div>
            <h4>
              {!event.allday && (
                <Moment format="HH:mm" locale="de">
                  {event.start_time}
                </Moment>
              )}
            </h4>
          </div>
          <div>
            <h3>
              <StyledLink
                parallel={event.parallel}
                allday={event.allday}
                to={`/events/${event._meta.uid}`}
              >
                {event.title[0].text.toUpperCase()} <br />
                {event.subtitle && event.subtitle[0].text}
              </StyledLink>
            </h3>
            {event.formatdescription && <p>{event.formatdescription}</p>}
            <p>
              {(event.speaker || event.speakers[0].additional_speakers) &&
                (event._meta.lang === "en-gb" ? <>with </> : <>mit </>)}
              {event.speaker && (
                <>
                  <StyledLink
                    parallel={event.parallel}
                    allday={event.allday}
                    to={`/speaker/${event.speaker._meta.uid}`}
                  >
                    {event.speaker.title[0].text}
                    {event.speaker.role && ` (${event.speaker.role})`}
                    {event.speaker.angefragt && "*"}
                  </StyledLink>
                </>
              )}
              {event.speakers[0].additional_speakers &&
                event.speakers.map((speaker, index, additionals) => (
                  <>
                    {!speaker && <>, </>}
                    <StyledLink
                      parallel={event.parallel}
                      allday={event.allday}
                      to={`/speaker/${speaker.additional_speakers._meta.uid}`}
                    >
                      {speaker.additional_speakers.title[0].text}
                      {speaker.additional_speakers.role &&
                        ` (${speaker.additional_speakers.role})`}
                      {speaker.additional_speakers.angefragt && "*"}
                    </StyledLink>
                    {!(index === additionals.length - 1) && <>, </>}
                  </>
                ))}
            </p>
            <p>
              {event.moderation && (
                <>
                  {"Moderation: "}
                  <StyledLink
                    parallel={event.parallel}
                    allday={event.allday}
                    to={`/speaker/${event.moderation._meta.uid}`}
                  >
                    {event.moderation.title[0].text}
                    {event.moderation.angefragt && "*"}
                  </StyledLink>
                </>
              )}
            </p>
            {event.location && <p>{event.location[0].text}</p>}
            <br />
            <p>{event.musik && RichText.render(event.musik)}</p>
          </div>
          <div>
            {event.speaker && (
              <div>
                <StyledLink
                  parallel={event.parallel}
                  allday={event.allday}
                  to={`/speaker/${event.speaker._meta.uid}`}
                >
                  <h3 style={{ fontStyle: "italic" }}>
                    {event.speaker.title[0].text}
                    {event.speaker.angefragt && "(*angefragt)"}
                  </h3>
                </StyledLink>
                {event.speaker.excerpt &&
                  RichText.render(event.speaker.excerpt)}
              </div>
            )}
            {event.speakers[0].additional_speakers &&
              event.speakers.map(speaker => (
                <div>
                  <StyledLink
                    parallel={event.parallel}
                    allday={event.allday}
                    to={`/speaker/${speaker.additional_speakers._meta.uid}`}
                  >
                    <h3 style={{ fontStyle: "italic" }}>
                      {speaker.additional_speakers.title[0].text}{" "}
                      {speaker.additional_speakers.angefragt && "(*angefragt)"}
                      {speaker.additional_speakers.role &&
                        ` (${speaker.additional_speakers.role})`}
                    </h3>
                  </StyledLink>
                  <div style={{ fontStyle: "italic" }}>
                    {speaker.additional_speakers.excerpt &&
                      RichText.render(speaker.additional_speakers.excerpt)}
                  </div>
                </div>
              ))}
            {event.moderation && (
              <div>
                <StyledLink
                  parallel={event.parallel}
                  allday={event.allday}
                  to={`/speaker/${event.moderation._meta.uid}`}
                >
                  <h3 style={{ fontStyle: "italic" }}>
                    {event.moderation.title[0].text}
                    {event.moderation.angefragt && "(*angefragt)"}
                  </h3>
                </StyledLink>
                <div style={{ fontStyle: "italic" }}>
                  {event.moderation.excerpt &&
                    RichText.render(event.moderation.excerpt)}
                </div>
              </div>
            )}
            {event.musicians &&
              event.musicians[0].musiker &&
              event.musicians.map(speaker => (
                <div>
                  <StyledLink
                    parallel={event.parallel}
                    allday={event.allday}
                    to={`/speaker/${speaker.musiker._meta.uid}`}
                  >
                    <h3 style={{ fontStyle: "italic" }}>
                      {speaker.musiker.title[0].text}{" "}
                    </h3>
                  </StyledLink>
                  <div style={{ fontStyle: "italic" }}>
                    {speaker.musiker.excerpt &&
                      RichText.render(speaker.musiker.excerpt)}
                  </div>
                </div>
              ))}
          </div>
        </Content>
      </Border>
    </>
  )
}
export default ProgramItem
