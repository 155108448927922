import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"

const Heading = styled.h1`
  padding: 1rem;
`
const Image = styled.img`
  transition: all 0.2s ease;
  box-shadow: none;
`
const Speaker = styled.div`
  width: 25%;
  padding: 1rem;
  transition: 0.4s all cubic-bezier(0.215, 0.61, 0.355, 1);
  @media only screen and (max-width: 800px) {
    width: 50%;
  }
  h2 {
    margin: 0;
  }
  a {
    color: #000;
    text-decoration: none;
    font-weight: 500;
  }
  :hover {
    img {
      transform: translateY(-5px);
    }
  }
`
const SpeakerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  transition: 0.3s all cubic-bezier(0.215, 0.61, 0.355, 1);
`

function Speakerlist(props) {
  return (
    <>
      <Heading>{props.heading}</Heading>
      <SpeakerList>
        {props.speakers.map(
          mapped =>
            mapped.speaker && (
              <Speaker
                key={mapped.speaker._meta.uid}
                className={mapped.speaker._meta.uid}
              >
                <Link
                  to={`${props.en ? "/en" : ""}/speaker/${
                    mapped.speaker._meta.uid
                  }`}
                >
                  <Image
                    src={mapped.speaker.thumbnail.medium.url}
                    alt={mapped.speaker.thumbnail.alt}
                    style={{ marginBottom: "1rem" }}
                  />
                  <h2>{mapped.speaker.title[0].text}</h2>
                </Link>
              </Speaker>
            )
        )}
      </SpeakerList>
    </>
  )
}
export default Speakerlist
